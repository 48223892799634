body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #B4CFEC;
  touch-action: manipulation;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  border: none;
}

td.Visited.Center.AnswerGrid{
  background-color: gray;
}
td.Visited.AnswerGrid{
  background-color: green;
}
td.Current.AnswerGrid{
  background-color: yellow;
}

td.AnswerGrid {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  border: 1px solid black;
  text-align: center;
  width: 13%;
  height: 0;
  padding-top: 1%;
  padding-bottom: 1%;
}

table.AnswerGrid {
  table-layout: fixed;
  width: 91%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
